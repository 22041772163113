<script>
export default {
  name: 'Events',
  props: {
    value: {
      type: [Array],
      required: true,
    },
  },
  computed: {
    isArray() {
      return Array.isArray(this.value);
    },
  },
  methods: {
    formatCurrency(value) {
      if (Number.isNaN(parseFloat(value))) return value;
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(value);
    },
  },
};
</script>

<template lang="pug">
span
  ul(v-if="isArray")
    li(v-for="item in value" :key="item.name")
      | {{ item.name }} ({{ item.label }}) - {{ formatCurrency(item.payout) }} {{ item.payout_mode }}
  span(v-else="else") {{ value }}
</template>
